import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Number/Index.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = ["data-cy"];
const _hoisted_2 = { class: "font-mono pl-3" };
const _hoisted_3 = { class: "text-gray-400 text-sm" };
const _hoisted_4 = { class: "text-left cursor-pointer" };
const _hoisted_5 = {
  key: 0,
  class: "w-6 h-6"
};
const _hoisted_6 = { class: "ml-3" };
const _hoisted_7 = {
  class: "text-gray-200 font-semibold",
  "data-cy": "funding-payments-ticker-name-table-data"
};
const _hoisted_8 = { class: "text-right font-mono pr-3" };
const _hoisted_9 = { class: "text-sm text-gray-500" };
const _hoisted_10 = { class: "text-gray-500" };
import { UI_DEFAULT_MAX_DISPLAY_DECIMALS } from "@/app/utils/constants";
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Row",
  props: {
    fundingPayment: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const { market, time, total, minimalDisplayAmount } = useFundingPayment(
      computed(() => props.fundingPayment)
    );
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppNumber = __nuxt_component_2;
      return _unref(market) ? (_openBlock(), _createElementBlock("tr", {
        key: 0,
        "data-cy": "funding-payments-table-row-" + _unref(market).ticker,
        class: "h-12"
      }, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(time)), 1)
        ]),
        _createElementVNode("td", _hoisted_4, [
          _createVNode(_component_NuxtLink, {
            class: "flex items-center justify-start",
            to: _unref(marketRoute)
          }, {
            default: _withCtx(() => [
              _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _unref(market).baseToken ? (_openBlock(), _createBlock(_component_CommonTokenIcon, {
                  key: 0,
                  token: _unref(market).baseToken
                }, null, 8, ["token"])) : _createCommentVNode("", true)
              ])) : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(market).ticker), 1)
              ])
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("td", _hoisted_8, [
          _unref(total).abs().gt(_unref(minimalDisplayAmount)) ? (_openBlock(), _createBlock(_component_AppNumber, {
            key: 0,
            "data-cy": "funding-payments-total-table-data",
            class: _normalizeClass({
              "text-green-500": _unref(total).gte(0),
              "text-red-500": _unref(total).lt(0)
            }),
            decimals: _unref(UI_DEFAULT_MAX_DISPLAY_DECIMALS),
            number: _unref(total)
          }, {
            addon: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(market).quoteToken.symbol), 1)
            ]),
            _: 1
          }, 8, ["class", "decimals", "number"])) : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass({
              "text-green-500": _unref(total).gte(0),
              "text-red-500": _unref(total).lt(0)
            })
          }, [
            _createTextVNode(_toDisplayString(`< ${_unref(minimalDisplayAmount).toFormat(6)}`) + " ", 1),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(market).quoteToken.symbol), 1)
          ], 2))
        ])
      ], 8, _hoisted_1)) : _createCommentVNode("", true);
    };
  }
});
