import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/TokenIcon.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Number/Index.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Common/Table/Row.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex items-center justify-between col-span-2 text-xs leading-5 pb-1" };
const _hoisted_2 = { class: "flex items-center justify-start" };
const _hoisted_3 = {
  key: 0,
  class: "w-4 h-4"
};
const _hoisted_4 = { class: "ml-1" };
const _hoisted_5 = { class: "text-gray-200 font-semibold text-xs" };
const _hoisted_6 = { class: "text-gray-200 text-xs font-mono" };
const _hoisted_7 = { class: "text-2xs text-gray-500" };
import { UI_DEFAULT_MAX_DISPLAY_DECIMALS } from "@/app/utils/constants";
import { getMarketRoute } from "@/app/utils/market";
export default /* @__PURE__ */ _defineComponent({
  __name: "Mobile",
  props: {
    fundingPayment: {
      required: true,
      type: Object
    }
  },
  setup(__props) {
    const props = __props;
    const { market, time, total, minimalDisplayAmount } = useFundingPayment(
      computed(() => props.fundingPayment)
    );
    const marketRoute = computed(() => {
      if (!market.value) {
        return void 0;
      }
      return getMarketRoute(market.value);
    });
    return (_ctx, _cache) => {
      const _component_CommonTokenIcon = __nuxt_component_0;
      const _component_NuxtLink = __nuxt_component_1;
      const _component_AppNumber = __nuxt_component_2;
      const _component_CommonTableRow = __nuxt_component_3;
      return _unref(market) ? (_openBlock(), _createBlock(_component_CommonTableRow, {
        key: 0,
        dense: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_NuxtLink, {
              to: _unref(marketRoute),
              class: "flex flex-col cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _unref(market).baseToken ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_CommonTokenIcon, {
                      token: _unref(market).baseToken,
                      sm: ""
                    }, null, 8, ["token"])
                  ])) : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(market).ticker), 1)
                  ])
                ]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(time)), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("div", null, [
              _unref(total).abs().gt(_unref(minimalDisplayAmount)) ? (_openBlock(), _createBlock(_component_AppNumber, {
                key: 0,
                "data-cy": "funding-payments-total-table-data",
                class: _normalizeClass({
                  "text-green-500": _unref(total).gte(0),
                  "text-red-500": _unref(total).lt(0)
                }),
                decimals: _unref(UI_DEFAULT_MAX_DISPLAY_DECIMALS),
                prefix: _unref(total).lt(0) ? "-" : "",
                number: _unref(total)
              }, {
                addon: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(market).quoteToken.symbol), 1)
                ]),
                _: 1
              }, 8, ["class", "decimals", "prefix", "number"])) : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass({
                  "text-green-500": _unref(total).gte(0),
                  "text-red-500": _unref(total).lt(0)
                })
              }, _toDisplayString(`< ${_unref(minimalDisplayAmount).toFormat(6)}`), 3))
            ])
          ])
        ]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
});
