import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Common/Subaccount/FundingPayment/Mobile.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Common/Subaccount/FundingPayment/Header.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/FundingPayment/Row.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "table"
};
import { Status } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "FundingPayments",
  props: {
    status: {
      type: Object,
      default: () => new Status()
    }
  },
  setup(__props) {
    const activityStore = useActivityStore();
    const fundingPayments = computed(() => activityStore.subaccountFundingPayments);
    return (_ctx, _cache) => {
      const _component_PartialsCommonSubaccountFundingPaymentMobile = __nuxt_component_0;
      const _component_CommonEmptyList = __nuxt_component_1;
      const _component_CommonTableBody = __nuxt_component_2;
      const _component_PartialsCommonSubaccountFundingPaymentHeader = __nuxt_component_3;
      const _component_PartialsCommonSubaccountFundingPaymentRow = __nuxt_component_4;
      const _component_CommonTableWrapper = __nuxt_component_5;
      const _component_AppHocLoading = __nuxt_component_6;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        status: __props.status,
        "loader-class": __props.status.isLoading() ? "relative" : ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CommonTableBody, {
            "show-empty": _unref(fundingPayments).length === 0,
            class: "sm:hidden mt-3 max-h-lg overflow-y-auto"
          }, {
            empty: _withCtx(() => [
              _createVNode(_component_CommonEmptyList, {
                message: _ctx.$t("fundingPayments.emptyFundingPayments"),
                class: "pb-4 grow bg-gray-900"
              }, null, 8, ["message"])
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fundingPayments), (fundingPayment, index) => {
                return _openBlock(), _createBlock(_component_PartialsCommonSubaccountFundingPaymentMobile, {
                  key: `mobile-funding-payment-${index}`,
                  "funding-payment": fundingPayment
                }, null, 8, ["funding-payment"]);
              }), 128))
            ]),
            _: 1
          }, 8, ["show-empty"]),
          _createVNode(_component_CommonTableWrapper, {
            "break-md": "",
            class: "hidden sm:block"
          }, {
            default: _withCtx(() => [
              _unref(fundingPayments).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_1, [
                _createVNode(_component_PartialsCommonSubaccountFundingPaymentHeader),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fundingPayments), (fundingPayment, index) => {
                    return _openBlock(), _createBlock(_component_PartialsCommonSubaccountFundingPaymentRow, {
                      key: `funding-payments-${index}-${fundingPayment.marketId}`,
                      "funding-payment": fundingPayment
                    }, null, 8, ["funding-payment"]);
                  }), 128))
                ])
              ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
                key: 1,
                "data-cy": "universal-table-nothing-found",
                message: _ctx.$t("fundingPayments.emptyFundingPayments"),
                class: "pb-4 grow"
              }, null, 8, ["message"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["status", "loader-class"]);
    };
  }
});
